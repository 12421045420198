import React, {Component} from 'react';
import './TowerRow.scss'

export default class TowerRow extends Component {


    render() {
        return (
            <div className="tower-row" onClick={() => this.props.onClick(this.props.tower)}>
                <img className="icon" src="/images/pin.svg" />
                <div className="tower-text">{this.props.tower.name}</div>
            </div>
        );
    }

}
