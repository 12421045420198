import TowermapInstance from './AxiosInstance';
import Tower from '../models/Tower';
import Category from '../models/Category';

export default class TowerApi {

    static async getTowersByBound(bounds, filters) {
        try {
            const response = await TowermapInstance.post(`towers/bounds/${bounds.ne.lat},${bounds.ne.lng},${bounds.sw.lat},${bounds.sw.lng}`, {filters});
            return response.data.map(t => new Tower(t));
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    static async getCategories() {
        try {
            const response = await TowermapInstance.get(`categories`);
            return response.data.map(t => new Category(t));
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    static async sendLead(tower, formData) {
        try {
            formData["tower_id"] = tower.id;
            const response = await TowermapInstance.post(`leads`, formData);
            return response.data.result === "success";
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}

