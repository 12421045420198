import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Link, Route, Switch} from 'react-router-dom';
import Router from './Router';

class AppContainer extends Component {
  render() {
    return (
      <BrowserRouter>
        <Route component={Router} />
      </BrowserRouter>
    );
  }
}
if (document.getElementById('map_app')) {
    ReactDOM.render(<AppContainer />, document.getElementById('map_app'));
}

