import React from 'react';
import {BrowserRouter, Link, Route, Switch} from 'react-router-dom';
import MainComponent from './components/Map/MainComponent';
import NotFound from './components/NotFound/NotFound'

const Router = props => (
<Switch>
  <Route exact path='/map' component={MainComponent}/>
  <Route path='/map/tower/:idTower' component={MainComponent}/>
  <Route component={NotFound}/>
</Switch>
);
export default Router;
