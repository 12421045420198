import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Autocomplete } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';

import _ from 'lodash';

import TowerApi from '../../services/TowerApi';

import TowerRow from './TowerRow';
import FiltersComponent from './FiltersComponent';
import TowerDetailComponent from './TowerDetailComponent';

import './MainComponent.scss';
import './MapComponent.scss'
import './LeftColumn.scss'
import FormModalComponent from './FormModalComponent';


const LoadingSvg = ({show}) => {
    if (show) {
        return (
            <div className="loader">
                <img src="/images/loading.svg" />
            </div>
        )
    }
    return (null)
}


export class MainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.libraries = ['places'];
        this.startingZoom = 7;
        this.startingCenter = {
            lat: 41.94328202030885,
            lng: 11.277432418749983
        };
        this.autocompleteRef = React.createRef();
        this.autocomplete = null;
        this.mapObject = null;
        this.filters = {}
        this.bounds = null
        this.lastCenter = null;
        this.lastZoom = null;
        this.state = {
            towers: [],
            loading: false,
            bounds: null,
            selectedTower: null,
            loadAutocomplete: false,
            zoom: this.startingZoom,
            modalIsOpen: false,
            center: this.startingCenter,
        };
        this.onLoadAutocomplete = this.onLoadAutocomplete.bind(this)
        this.onPlaceChanged = this.onPlaceChanged.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        this.onZoomChanged = this.onZoomChanged.bind(this)
    }

    onLoadAutocomplete = (autocomplete) => {
        this.autocomplete = autocomplete
    }

    onPlaceChanged () {
        if (this.autocomplete !== null) {
          const place = this.autocomplete.getPlace();
          if (place && place.geometry) {
            this.setState({
                center: place.geometry.location,
                zoom: 14,
            }, () => {
                this.mapObject.setZoom(14);
                this.handleMapChanges();
            })
          }
        }
    }

    fetchTowers = async () => {
        try {
            this.setState({ loading: true });
            const towers = await TowerApi.getTowersByBound(this.bounds, this.filters);
            this.setState({
                towers,
                loading: false,
            });
        } catch (error) {
            console.log(error);
            this.setState({
                loading: true,
            });
        }

    }

    onMapReady = (map) => {
        this.mapObject = map;
        setTimeout(this.findUserPosition, 500);
    }

    findUserPosition = async () => {
        try {
            if ("geolocation" in navigator) {
                // this.setState({loading: true});
                navigator.geolocation.getCurrentPosition((position) => {
                    if (position) {
                        this.setState({
                            center: {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            },
                            zoom: 14
                        }, () => {
                            this.handleMapChanges();
                        })
                    // } else {
                    //     this.setState({loading: false});
                    }
                });
            }
        } catch (error) {
            console.log(error);
            // this.setState({loading: false});
        }

    }

    onDragEnd = (mapProps, map) => {
        this.handleMapChanges();
    }

    onZoomChanged = () => {
        this.handleMapChanges();
    }

    setMapInfo = async () => {
        if (!this.mapObject)
            return false;

        var zoom = this.mapObject.getZoom();
        if (zoom < 9) {
            this.setState({
                towers: []
            });
            return false;
        }

        var bounds = await this.mapObject.getBounds();
        if (bounds != undefined) {
            var ne = bounds.getNorthEast();
            var sw = bounds.getSouthWest();
            this.bounds = {
                ne: {
                    lat: ne.lat(),
                    lng: ne.lng()
                },
                sw: {
                    lat: sw.lat(),
                    lng: sw.lng()
                },
            }
        } else {
            return false;
        }

        return true;

    }

    handleMapChanges = async () => {

        this.setState({loading: false});
        if (!this.mapObject)
            return;

        const setMapInfo = await this.setMapInfo();
        if (setMapInfo) {
            await this.fetchTowers();
        }

    }

    onClickMarker = (tower) => {
        this.lastCenter = this.mapObject.getCenter();
        this.lastZoom = this.mapObject.getZoom();
        this.onRowClick(tower);
    }

    onRowClick = (tower) => {
        this.mapObject.setCenter(tower.getCenter());
        this.mapObject.setZoom(17);
        this.setState({
            selectedTower: tower
        })
    }

    onFiltersChange = (filters) => {
        this.filters = filters;
        this.handleMapChanges();
    }

    backToResults = (filters = false) => {
        this.mapObject.setZoom(this.startingZoom);
        this.mapObject.setCenter(this.startingCenter);
        this.autocompleteRef.current.value = null;
        if (filters) {
            $(".tower-detail-container__search-button")[0].click();
        }
        this.setState({
            selectedTower: null,
        })
    }

    onContactClick = () => {
        this.setState({
            modalIsOpen: !this.state.modalIsOpen
        })
    }

    getMarkers = () => {

        const markers = this.state.towers.map(tower => {
            const location = {
                lat: parseFloat(tower.latitude),
                lng: parseFloat(tower.longitude),
            }
            const selected = this.state.selectedTower && tower.id === this.state.selectedTower.id
            return (<Marker
                        key={tower.id}
                        position={location}
                        onClick={() => {
                            this.onClickMarker(tower);
                        }}
                        icon={{
                            url: selected ? "/images/pin-selected.svg" : "/images/pin.svg",
                            anchor: selected ? new google.maps.Point(32, 44) : new google.maps.Point(16, 22),
                            scaledSize: selected ? new google.maps.Size(80, 110) : new google.maps.Size(40, 55)
                        }}
                    />)
        })
        return markers
    }


    render() {

        var towerRows = this.state.towers.map(t => {
            return (<TowerRow
                        tower={t}
                        key={t.id}
                        onClick={this.onRowClick.bind(this)} />)
        })

        if (towerRows.length == 0) {
            towerRows = (<div className="results-container__no-results">Utilizza il campo di ricerca oppure usa la mappa per posizionarti sulla tua area di interesse</div>)
        }

        const leftColumnContent = !this.state.selectedTower ?
            towerRows :
            (<TowerDetailComponent
                tower={this.state.selectedTower}
                onContactClick={this.onContactClick.bind(this)}
                backToResults={this.backToResults.bind(this)}
                />);

        const modal = !this.state.selectedTower ? (null) : (
            <FormModalComponent
                modalIsOpen={this.state.modalIsOpen}
                tower={this.state.selectedTower}
                onCloseModal={this.onContactClick} />
        )

        const clusterOptions = {
            imagePath:
              'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        }

    	return(
            <div className="main-map-container">
                <LoadScript
                    googleMapsApiKey={process.env.MIX_GOOGLE_MAP_KEY}
                    libraries={this.libraries}
                    >

                    <LoadingSvg show={this.state.loading} />
                    {modal}
                    <div className="left-column">

                            <Autocomplete
                            style={{width: "100%", height: "100%"}}
                                onLoad={this.onLoadAutocomplete}
                                onPlaceChanged={this.onPlaceChanged}
                            >
                                <div className="input-container">
                                    <input
                                        ref={this.autocompleteRef}
                                        value={this.state.placeName}
                                        type="text"
                                        className="search-input"
                                    />
                                </div>
                            </Autocomplete>

                        <FiltersComponent
                            filters={this.filters}
                            backToResults={this.backToResults.bind(this)}
                            onFiltersChange={this.onFiltersChange.bind(this)} />
                        <div className="results-container">
                            {leftColumnContent}
                        </div>
                    </div>

                    <div style={{width: "100%", height: "100%", flex: 1}}>

                            <GoogleMap
                                onLoad={this.onMapReady}
                                onDragEnd={this.onDragEnd}
                                onZoomChanged={this.onZoomChanged}
                                mapContainerClassName="map-block"
                                center={this.state.center}
                                zoom={this.state.zoom}
                                streetView={undefined}
                                options={{streetViewControl: false}}
                            >
                                {this.getMarkers()}
                            </GoogleMap>

                    </div>
                </LoadScript>
            </div>
    	)
    }

}


export default MainComponent;
