import React from 'react';
import ReactDOM from 'react-dom';

export default class NotFound extends React.Component {
    render() {
      return (
        <div>
          OPS 404
        </div>
      );
    }
  }

