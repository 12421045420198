window._ = require('lodash');


/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');



	// Smooth scroll
	$('a.mouse').click(function() {
	    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
	        var target = $(this.hash);
	        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');

	        if (target.length) {
	            $('html, body').animate({
	              scrollTop: target.offset().top - 100
	            }, 1000);
	            return false;
	        }
	    }
	});



    function isScrolled() {
	    if( $(window).scrollTop() >= 40 ) {
	        $( 'body' ).addClass( 'scrolled' );
	    } else {
	        $( 'body' ).removeClass( 'scrolled' );
	    }
    };

    $(window).on( 'load scroll', function(){
        isScrolled();    	
    })



	function getContainerDistance( $container ) {
	    return ( $(window).width() - $container.outerWidth() ) / 2;
	}


	/* debounce */
	(function($,sr){

		// debouncing function from John Hann
		// http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
		var debounce = function (func, threshold, execAsap) {
			var timeout;

			return function debounced () {
				var obj = this, args = arguments;
				function delayed () {
					if (!execAsap)
						func.apply(obj, args);
					timeout = null;
				}

				if (timeout)
					clearTimeout(timeout);
				else if (execAsap)
					func.apply(obj, args);

				timeout = setTimeout(delayed, threshold || 100);
			};
		};
		// smartresize
		jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };

	})(jQuery,'smartresize');


	const bps =  {
		smartphone: {
			label: 'xs',
			enter: 0,
			exit: 320				
		},
		smartphone: {
			label: 'sm',
			enter: 576,
			exit: 767				
		},
		tablet: {
			label: 'md',
			enter: 768,
			exit: 1024
		},
		desktop: {
			label: 'lg',
			enter: 992,
			exit: 20000			
		}
	};


	function onResize(){

		/* breakpoints helper classes on body */
		$( 'body' ).removeClass(function (index, css) {
			return (css.match (/\bbp-\S+/g) || []).join(' ');
		});

		if( window.matchMedia( "(max-width: " + bps.smartphone.exit +"px)" ).matches ) {
			$( 'body' ).addClass( 'bp-' + bps.smartphone.label);
		}

		if( window.matchMedia( "(min-width: " + bps.tablet.enter +"px)" ).matches && window.matchMedia( "(max-width: " + bps.tablet.exit +"px)" ).matches ) {
			$( 'body' ).addClass( 'bp-' + bps.tablet.label);
		}

		if( window.matchMedia( "(min-width: " + bps.desktop.enter +"px)" ).matches ) {
			$( 'body' ).addClass( 'bp-' + bps.desktop.label);
		}

		/* orientation helper classes on body */
		var mql = window.matchMedia("(orientation: portrait)");

		// If there are matches, we're in portrait
		if(mql.matches) {
		  // Portrait orientation
		  if( ! $( 'body' ).hasClass( 'portrait' ) ) {
				$( 'body' ).removeClass( 'landscape' );
				$( 'body' ).addClass( 'portrait' );
		  }
		} else {
		  // Landscape orientation
		  if( ! $( 'body' ).hasClass( 'landscape' ) ) {
				$( 'body' ).removeClass( 'portrait' );
				$( 'body' ).addClass( 'landscape' );
		  }
		}


		if( $( 'body:not(.bp-xs) .carousel' ).length ) {
			let $container = $( '.section--features .container' ),
				$containerPaddingLeft = parseInt( $container.css('paddingLeft') );
			$( '.carousel .slick-track' ).css( 'left', ( getContainerDistance( $container ) + $containerPaddingLeft ) + 'px' );
		}

	}



	// applico debounce al resize
	$(window).smartresize(function(){
		onResize();
	});

	// applico debounce al load
	$(window).on( 'load', function() {
		onResize();
	});




	if( $( '.carousel' ).length ) {

	    require('slick-carousel');

		let $carousel = $( '.carousel' ),
			options = {
				slide: '.carousel-slide',
				infinite: false,
				arrows: false,
				touchThreshold: 20,
			    mobileFirst: true,
			    rows: 0,
			};


		options.responsive = [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4.5,
					slidesToScroll: 1
				}
			}, 
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3.5,
					slidesToScroll: 1
				}
			}, 
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			}, 
			{
				breakpoint: 570,
				settings: {
					slidesToShow: 2.5,
					slidesToScroll: 1
				}
			}, 
			{
				breakpoint: 320,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		];



		if( $carousel.data( 'autoplay' ) ) {
		    options.autoplay = $carousel.data( 'autoplay' );
		}

		if( $carousel.data( 'autoplayspeed' ) ) {
		    options.autoplaySpeed = $carousel.data( 'autoplayspeed' );
		}


	    $carousel.on('init', function (event, slick) {
	        slick.$slides.css('height', slick.$slideTrack.height() + 'px');
	    });
	    
	    $carousel.slick(options);


		// Carousel nav
		if( $( '.carousel__nav' ).length ) {

			// PREV
			$( '.carousel__arrow--prev' ).on( 'click', function(e) {
			    $(this).parents( '.section--features' ).find( '.carousel' ).slick( 'slickPrev' );
			});

			// NEXT
			$( '.carousel__arrow--next' ).on( 'click', function(e) {
			    $(this).parents( '.section--features' ).find( '.carousel' ).slick( 'slickNext' );
			});

		}



		if( $( '.slider' ).length ) {

		    require('slick-carousel');

			let $slider = $( '.slider' ),
				options = {
					slide: '.slide',
					infinite: true,
					slidesToShow: 1,
					arrows: false,
					touchThreshold: 20,
					dots: false,
					rows: false
				};

			$slider.slick(options);
		}

		// slider nav
		if( $( '.slider__nav' ).length ) {

			// PREV
			$( '.slider__arrow--prev' ).on( 'click', function(e) {
			    $(this).parents( '.section--bg-shape' ).find( '.slider' ).slick( 'slickPrev' );
			});

			// NEXT
			$( '.slider__arrow--next' ).on( 'click', function(e) {
			    $(this).parents( '.section--bg-shape' ).find( '.slider' ).slick( 'slickNext' );
			});

		}

	}


	if( $( '.cta' ).length ) {
		$( '.cta' ).on( 'click', function(e) {
			e.preventDefault();

			let $form = $(this).attr( 'href' );

			( ! $( 'body' ).hasClass( 'modal-form-open' ) ) ? $( 'body' ).addClass( 'modal-form-open' ) : '';
			$( $form ).toggleClass( 'modal-form--show' );
		} )
	}


	if( $( '.modal-form__close' ).length ) {
		$( '.modal-form__close' ).on( 'click', function(e) {
			e.preventDefault();

			let $form = $(this).attr( 'href' );

			( $( 'body' ).hasClass( 'modal-form-open' ) ) ? $( 'body' ).removeClass( 'modal-form-open' ) : '';
			$(this).parents('.modal-form').removeClass( 'modal-form--show' );
		} )
	}



	if( $( '.form--modal' ).length ) {
		$( '.form--modal' ).on( 'submit', function(e) {
			e.preventDefault();

			let data = {}, $fields = $(this).serializeArray();

		    jQuery.each( $fields, function( i, field ) {
		    	data[field.name] = field.value;
		    });

			data['_token'] = $('meta[name="csrf-token"]').attr('content');
			data['_method'] = 'post';

			$.ajax({

			    type: "POST",
			    dataType: "json",
			    url: '/contact',
			    data: data,

			}).done( function(response) {

			    if( response.result == 'success' ) {

			    	$( '.form--modal' ).parent().append(
			    		'<div class="alert alert-success" role="alert">' +
						  response.output +
						'</div>'
			    	);

			    } else {

			    	$( '.form--modal' ).parent().append(
			    		'<div class="alert alert-danger" role="alert">' +
						   response.output +
						'</div>'
			    	);

			    }
			    
			}).fail( function(xhr) {

			    console.log(xhr.responseText);

			});
		})
	}


	$( '#menu-toggle' ).on( 'click', function(e){
		e.preventDefault();

		$( 'body' ).toggleClass( 'menu-open' );

		$(this).toggleClass('is-active');

		$(this).attr( 'aria-expanded', function(i, attr){
			return attr = 'false' ? 'true' : 'false';
		});
	});


	$( '#menu-close' ).on('click', function (e) {
		e.preventDefault();

		$('body').removeClass('menu-open');

		$('#menu-close').attr('aria-expanded', function (i, attr) {
			return attr == 'true' ? 'false' : 'true';
		});

		$( '#menu-toggle' ).removeClass('is-active');

	});

} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
  window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
  } else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
