import React, {Component} from 'react';
import Carousel from 'nuka-carousel';

import './TowerDetailComponent.scss'

export default class TowerDetailComponent extends Component {

    getPhotos = () => {
        if (this.props.tower.photos.length === 0 ) {
            return (null)
        }

        const photos = (this.props.tower.photos.length === 0) ? (null) :
                this.props.tower.photos.map((p, index) => {
                    return (<img key={index} src={`/storage/${p}`} />)
                })

        return (<div className="slider-container" >
                    <Carousel>
                        {photos}
                    </Carousel>
                </div>)
    }

    render() {

        return (
            <div className="tower-detail-container">
                <div>
                    <span className="back-link" onClick={(e)=> { e.preventDefault(); this.props.backToResults(true)}}>&lt; Torna alla ricerca</span>
                </div>

                <div className="tower-info">
                    <strong>{this.props.tower.name}:</strong> {this.props.tower.description}
                </div>

                {this.getPhotos()}

                <div className="contact-row">
                    <span>Per saperne di più</span>
                    <div className="contact-cta" onClick={this.props.onContactClick}>
                        Scrivici
                        <img className="contact-cta__image" src="/images/pen.svg" />
                    </div>
                </div>

                <table className="info-table">
                    <thead>
                        <tr className="info-table__header-tr">
                            <td colSpan="2">Dettagli</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="info-table__body-tr">
                            <td className="info-table__body-tr__label">
                                Categoria
                            </td>
                            <td className="info-table__body-tr__value">
                                {this.props.tower.category.name}
                            </td>
                        </tr>
                        <tr className="info-table__body-tr">
                            <td className="info-table__body-tr__label">
                                Indirizzo
                            </td>
                            <td className="info-table__body-tr__value">
                                {this.props.tower.address}
                            </td>
                        </tr>
                        <tr className="info-table__body-tr">
                            <td className="info-table__body-tr__label">
                                Coordinate
                            </td>
                            <td className="info-table__body-tr__value">
                                {this.props.tower.latitude} {this.props.tower.longitude}
                            </td>
                        </tr>
                        <tr className="info-table__body-tr">
                            <td className="info-table__body-tr__label">
                                Codice operatore
                            </td>
                            <td className="info-table__body-tr__value">
                                {this.props.tower.operator_name}
                            </td>
                        </tr>
                        <tr className="info-table__body-tr">
                            <td className="info-table__body-tr__label">
                                Identificatore del sito dell'operatore
                            </td>
                            <td className="info-table__body-tr__value">
                                {this.props.tower.operator_site_identifier}
                            </td>
                        </tr>
                        { this.props.tower.ground_elevation > 0 ?
                            (<tr className="info-table__body-tr">
                                <td className="info-table__body-tr__label">
                                    Altezza dal suolo
                                </td>
                                <td className="info-table__body-tr__value">
                                    {this.props.tower.ground_elevation} {this.props.tower.units}
                                </td>
                            </tr>) : (null) }
                        { this.props.tower.amsl > 0 ?
                            (<tr className="info-table__body-tr">
                                <td className="info-table__body-tr__label">
                                    Altezza dal livello del mare
                                </td>
                                <td className="info-table__body-tr__value">
                                    {this.props.tower.amsl} {this.props.tower.units}
                                </td>
                            </tr>) : (null) }
                    </tbody>
                </table>
            </div>
        );
    }

}
