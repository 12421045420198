import React, {Component} from 'react';
import {Collapse} from 'react-collapse';
import InputRange from 'react-input-range';
import TowerApi from '../../services/TowerApi';

import 'react-input-range/lib/css/index.css';
import './FiltersComponent.scss';

export default class FiltersComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filtersOpen: false,
            filters: {
                idCategorySelected: -1,
                categories: [],
                operator: "",
                groundElevation:{
                    min: 0,
                    max: 4000
                }
            },
        }
    }

    componentDidMount = async () => {
        const categories = await TowerApi.getCategories();
        this.setState({
            filters: {
                ...this.state.filters,
                categories: categories
            }
        })
    }

    resetFilters = () => {
        this.setState({
            filters: {
                ...this.state.filters,
                idCategorySelected: -1,
                operator: "",
                groundElevation:{
                    min: 0,
                    max: 4000
                }
            }
        }, () => {
            this.props.backToResults()
        })
    }

    toggleOpenFilters = () => {
        this.setState({ filtersOpen: !this.state.filtersOpen})
    }

    updateFilter = (filter, value) => {
        var filters = this.state.filters;
        filters[filter] = value;
        this.setState({
            filters
        });
    }

    filtersChange = () => {
        this.props.onFiltersChange(this.state.filters)
    }

    _handleInputKeyDown = (event) => {
        if (event.key === "Enter") {
            this.filtersChange();
        }
    }

    render() {

        var options = !this.state.filters['categories'] ? [] : this.state.filters['categories'].map(c => {
            return (
                <option key={c.id} value={c.id} >{c.name}</option>
            )
        });

        options.splice(0,0,(
            <option key="-1" value="-1" >Scegli categoria</option>
        ))

        return (
            <div className="filters-block">
                <div className="header" onClick={this.toggleOpenFilters.bind(this)}>
                    <div className="title">Approfondisci la ricerca</div>
                    <div className={`toggle-info ${(this.state.filtersOpen ? "open" : "")}`}>Filtri</div>
                </div>
                <Collapse
                    isOpened={this.state.filtersOpen}
                    initialStyle={{
                        height: 0,
                        overflow: "hidden",
                        transition: "height 400ms",
                    }}>
                    <div className="filters-container">
                        <div className="filter">
                            <div className="filter-label">
                                Categoria
                            </div>
                            <div className="filter-value">
                                <select className="filter-select" value={this.state.filters.idCategorySelected} onChange={(event) => {
                                        this.updateFilter('idCategorySelected', event.target.value);
                                    }}>
                                    {options}
                                </select>
                            </div>
                        </div>
                        <div className="filter">
                            <div className="filter-label">
                                Operatore
                            </div>
                            <div className="filter-value">
                                <input
                                    className="filter-input"
                                    placeholder="Inserisci operatore"
                                    type="text"
                                    value={this.state.filters['operator']}
                                    onKeyDown={this._handleInputKeyDown.bind(this)}
                                    onChange={(event) => {
                                    this.updateFilter('operator', event.target.value);
                                }} />
                            </div>
                        </div>
                        <div className="filter full-width">
                            <div className="filter-label">Ground elevation</div>
                            <div className="filter-value">
                            <InputRange
                                maxValue={4000}
                                minValue={0}
                                formatLabel={value => `${value} mt`}
                                value={this.state.filters.groundElevation}
                                onChange={value => this.updateFilter('groundElevation', value)} />
                            </div>
                        </div>
                        <div className="buttons-container">
                            <a className="tower-detail-container__search-button" onClick={this.resetFilters.bind(this)}>Nuova ricerca</a>
                            <div className="button button--action" onClick={this.filtersChange.bind(this)}>Applica</div>
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }

}
