import React, { useState, useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import TowerApi from '../../services/TowerApi'

import './FormModalComponent.scss'

export default function FormModalComponent({tower, modalIsOpen, onCloseModal}) {

    const firstRender = useRef(true)
    const [disabled, setDisabled] = useState(true)
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        company: "",
        phone: "",
        request: "",
        privacy: false,
    })

    useEffect(() => {

        if (firstRender.current) {
          firstRender.current = false
          return
        }

        setDisabled(formValidation())

    }, [formData])

    const onChangeFormData = (field, event) => {
        if (field === 'privacy') {
            const checked = event.target.checked;
            setFormData({...formData, [field]: checked});
            return;
        }
        const value = event.target.value;
        setFormData({...formData, [field]: value});
    }

    const sendLead = async (event) => {
        event.preventDefault();
        const saved = await TowerApi.sendLead(tower, formData);
        if (saved) {
            alert('Richiesta inviata!');
            onCloseModal();
        } else {
            alert('Errore invio richiesta');
        }
    }



    const validate = (email) => {
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return expression.test(String(email).toLowerCase())
    }

    const formValidation = () => {

        const newErrors = {...errors};
        newErrors.first_name = (formData.first_name === "")
        newErrors.last_name = (formData.last_name === "")
        newErrors.email = (formData.email === "" || !validate(formData.email))
        newErrors.privacy = (formData.privacy === false)

        setErrors(newErrors);

        const results = Object.keys(newErrors).reduce((prev, current) => {
            return prev || newErrors[current];
        }, false);

        return results

    }

    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={modalIsOpen}
            onRequestClose={onCloseModal}
            style={
                {
                    overlay: {
                        zIndex: 999999,
                        backgroundColor: "rgba(0, 0, 0, 0.6)"
                    },
                    content: {
                        width: 630,
                        height: 514,
                        top                   : '50%',
                        left                  : '50%',
                        right                 : 'auto',
                        bottom                : 'auto',
                        marginRight           : '-50%',
                        transform             : 'translate(-50%, -50%)',
                        borderRadius : 0,
                        padding: 60
                    }
                }
            }
            contentLabel="Example Modal">
            <div className="tower-modal-content">
                <div className="tower-modal-content__title">
                    Richiedi informazioni per {tower.name}:
                </div>
                <div className="tower-modal-content__description">
                    {tower.description}
                </div>
                <div className="tower-modal-content__form-container">
                    <form className="tower-modal-form">
                        <div className="tower-modal-form__input-row">
                            <input
                                className={errors.first_name ? "tower-modal-form__input-row__input-text input-error" : "tower-modal-form__input-row__input-text"}
                                type="text"
                                value={formData.first_name}
                                onChange={(event) => {onChangeFormData('first_name', event)}}
                                placeholder="Nome *" />
                            <input
                                className={errors.last_name ? "tower-modal-form__input-row__input-text input-error" : "tower-modal-form__input-row__input-text"}
                                type="text"
                                value={formData.last_name}
                                onChange={(event) => {onChangeFormData('last_name', event)}}
                                placeholder="Cognome *" />
                        </div>
                        <div className="tower-modal-form__input-row">
                            <input
                                className={errors.email ? "tower-modal-form__input-row__input-text input-error" : "tower-modal-form__input-row__input-text"}
                                type="email"
                                value={formData.email}
                                onChange={(event) => {onChangeFormData('email', event)}}
                                placeholder="Email *" />
                            <input
                                className="tower-modal-form__input-row__input-text"
                                type="text"
                                value={formData.company}
                                onChange={(event) => {onChangeFormData('company', event)}}
                                placeholder="Azienda" />
                        </div>
                        <div className="tower-modal-form__input-row">
                            <input
                                className={errors.email ? "tower-modal-form__input-row__input-text input-error" : "tower-modal-form__input-row__input-text"}
                                type="text"
                                value={formData.phone}
                                onChange={(event) => {onChangeFormData('phone', event)}}
                                placeholder="Telefono" />
                        </div>
                        <div className="tower-modal-form__input-row">
                            <textarea
                                value={formData.request}
                                onChange={(event) => {onChangeFormData('request', event)}}
                                placeholder="Messaggio"
                                className="tower-modal-form__input-row__textarea"
                            />
                        </div>
                        <div className="tower-modal-form__footer">
                            <div className="tower-modal-form__footer__privacy-container">
                                <label className="container">Ho letto e accetto l'informativa sulla <a href="#" target="_blank">Privacy</a>
                                    <input type="checkbox"
                                        checked={formData.privacy}
                                        onChange={(event) => {onChangeFormData('privacy', event)}}/>
                                    <span className={errors.privacy ? "checkmark checkmark--error" : "checkmark"}></span>
                                </label>
                            </div>
                            <button
                                onClick={sendLead}
                                disabled={disabled}
                                className={disabled ? "btn tower-modal-form__footer__submit-button--disabled" : "btn"}>
                                Invia
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </ReactModal>
    );

}
