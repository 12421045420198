import _ from 'lodash';
import Category from './Category';

export default class Tower {
    constructor(dict) {
        this.id = dict['id'];
        this.code = dict['code'];
        this.name = dict['name'];
        this.operator_site_identifier = dict['operator_site_identifier'];
        this.operator_name = dict['operator_name'];
        this.description = dict['description'];
        this.fcc_asr_number = dict['fcc_asr_number'];
        this.faa_study_number = dict['faa_study_number'];
        this.units = dict['units'];
        this.agl = dict['agl'];
        this.amsl = dict['amsl'];
        this.ground_elevation = dict['ground_elevation'];
        this.stories = dict['stories'];
        this.status = dict['status'];
        this.category_id = dict['category_id'];
        this.category = new Category(dict['category']);
        this.address = dict['address'];
        this.latitude = dict['latitude'];
        this.longitude = dict['longitude'];
        this.setPhotos(dict);
    }

    setPhotos(dict) {
        this.photos = [];
        if (dict["photo_1"]) {
            this.photos.push(dict["photo_1"])
        }
        if (dict["photo_2"]) {
            this.photos.push(dict["photo_2"])
        }
        if (dict["photo_3"]) {
            this.photos.push(dict["photo_3"])
        }
        if (dict["photo_4"]) {
            this.photos.push(dict["photo_4"])
        }


    }

    getCenter() {
        return {
            lat: parseFloat(this.latitude),
            lng: parseFloat(this.longitude)
        }
    }
}
